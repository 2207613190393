








import { Component, Vue } from 'vue-property-decorator';
import { Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
@Component
export default class InsLayer extends Vue {
  public show:boolean = false;
  created () {
  }
  mounted () {
    if (this.$refs.layerbody) {
      Loading.service({
        target: this.$refs.layerbody as any,
        text: 'Loading',
        fullscreen: false,
        spinner: 'el-icon-loading'
      });
    }
  }
  updated () {
    if (this.$refs.layerbody) {
      Loading.service({
        target: this.$refs.layerbody as any,
        text: 'Loading',
        fullscreen: false,
        spinner: 'el-icon-loading'
      });
    }
  }
  public hidden () {
    setTimeout(() => {
      this.show = false;
      document.body.style.overflowY = 'auto';
    }, 500);
  }
  public showL () {
    this.show = true;
    setTimeout(() => { window.scrollTo(0, 0); }, 50);
  }
}
