module.exports = {
  // instore app id admin的id
  AppId: '30a30b50-19dc-44c7-adcc-eb9996da21d4', // edtyos
  // api server url 統一配置Api服務器的url
  ApiServer: 'https://api.uat.in-store.hk',
  // ApiServer: 'http://api.dev.in-store.hk:84',
  // api version for this app 統一配置Api版本號
  ApiVersion: 'V2',
  // api Authorization 身份认真，用于后端识别客户端
  Authorization: 'MzBhMzBiNTAtMTlkYy00NGM3LWFkY2MtZWI5OTk2ZGEyMWQ0OmE5NTQ0OTI1LWIwNWItNGUwYS04MDZhLTZkNGMzMjQ2ZWQ5OQ==',
  // admin server url, for login management platform 統一配置admin服務器
  AdminServer: 'https://admin.uat.in-store.hk',
  // AdminServer: 'http://admin.dev.in-store.hk:84',
  // url '/admin' auto open admin tab
  AutoOpenAdmin: true,
  // admin login url 統一配置admin登錄地址
  AdminLoginUrl: this.AdminServer + '/default/ClientLogin/' + this.AppId,
  // 前端默认配置
  FrontE: {
    defaultLang: 'C',
    defaultCurrency: 1,
    mobileBuilding: false,
    pcBuilding: false,
    cmsSeoList: ['20288', '20289', '20287'],
    productSeoList: ['Home']
  }
};
