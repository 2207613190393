







import { Component, Vue, Watch } from 'vue-property-decorator';
import Layer from '@/components/service/InsLayer.vue';
@Component({
  components: {
    Layer
  }
})
export default class App extends Vue {
  beforeCreate () {
    if (((Vue.prototype.userAgent === 'mobile' && this.FrontE.mobileBuilding) || (Vue.prototype.userAgent === 'pc' && this.FrontE.pcBuilding)) && (this.$route.name !== 'building')) {
      this.$router.push('/building');
    }
  }
  beforeUpdate () {
    // Vue.prototype.$ShowLayer();
    // setTimeout(() => { Vue.prototype.$HiddenLayer(); }, 2000);
    // Api.getData(null, 10);
    if (this.$route.name !== 'home') { return; }
    if (this.$route.query.returnUrl) {
      let url = this.$route.query.returnUrl + '?';
      Object.keys(this.$route.query).forEach((e) => {
        if (e !== 'returnUrl') url += e + '=' + this.$route.query[e] + '&';
      });
      this.$router.push(url);
    } else {
      this.$nextTick(() => {
        document.title = '型店';
      });
    }
  }
  created () {
    // this.changLange(this.$Storage.get('locale') || this.FrontE.defaultLang);
    let currency = this.$Storage.get('currency') ? this.$Storage.get('currency') : this.FrontE.defaultCurrency;
    this.$Api.member
      .setCurrency(currency)
      .then(result => {
        this.$store.dispatch('setCurrency', result);
        this.$Storage.set('currency', currency);
      })
      .catch(error => {
        console.log(error);
      });
  }
  private changLange (lang) {
    this.$Api.member
      .setUILanguage(lang)
      .then(result => {
        this.$i18n.locale = lang;
        this.$Storage.set('locale', lang);
      })
      .catch(error => {
        console.log(error);
      });
  }
}
